import { useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { loginWithCode, selectIdToken, selectAuthState, selectExpire, logout  } from "./authSlice";
import { Outlet, useLocation, useNavigate, Navigate } from "react-router-dom";
import { authEndpoint } from "./authAPI";
import { log } from "console";

export function SignIn() {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const idToken = useAppSelector(selectIdToken);
    const state = useAppSelector(selectAuthState);
    const expire = useAppSelector(selectExpire);
    const isValid = idToken && expire && expire > Date.now()

    const navigate = useNavigate();
    const redirectPath = '/i/playlists';

    useEffect(() => {
        console.debug('SignIn useEffect');
        const code = new URLSearchParams(location.search).get("code");
        if (isValid) {
            console.debug('session still valid,  redirecting to ', redirectPath);
            navigate(redirectPath, { replace: true });
        }
        else if(!code) {
            console.debug('no code, redirecting to ', authEndpoint);
            window.location.href = authEndpoint;
        }
        else {
            console.debug('code found, dispatching loginWithCode');
            dispatch(loginWithCode(code))
            .unwrap()
            .then(()=>navigate(redirectPath))
            .catch((rejectedValueOrSerializedError) => {
                console.error(rejectedValueOrSerializedError);
              })
        }
    },[]);

    return (
        <div>
            {state === 'failed' ? <Alert variant="danger">Alert: Sign in failed!</Alert> 
            : <div>Signing in...</div>}
        </div>
    );
}

export function SignOut() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        console.debug('SignOut useEffect');
        dispatch(logout());
        navigate('/');
    },[]);

    return (
        <div>
            <Alert variant="info">Signing out...</Alert>
        </div>
    );
}

export function RequireAuth() {
    const idToken = useAppSelector(selectIdToken);
    const expire = useAppSelector(selectExpire);

    // check if the token is valid during navigation; redirect to /signin if about to expire in a hours 
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const _location = useLocation(); 
    const isValid = idToken && expire && expire > Date.now() + 60000;

    console.debug('rendering RequireAuth');
    return (
        <>
            {isValid ? 
                <Outlet />
            :
            <>
                <Navigate to='/signin'/>
            </>}
        </>
    );
}
