import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { jwtDecode } from 'jwt-decode';
import { fetchToken } from './authAPI';


export interface AuthState {
    idToken: string | null;
    accessToken: string | null;
    userId: string | null;
    expire: number | null;
    state:  'idle' | 'loading' | 'failed';
}

// snake_case is used here because the Cognito API returns snake_case keys
export interface Tokens {
    id_token: string;
    access_token: string;
    expires_in: number;
}

const initialState: AuthState = {
    idToken: null,
    accessToken: null,
    userId: null,
    state: 'idle',
    expire: null // unix epoch in milliseconds
};

export const loginWithCode = createAsyncThunk(
    'auth/loginWithCode', // action type is 'auth/loginWithCode/pending', 'auth/loginWithCode/fulfilled', 'auth/loginWithCode/rejected
    async (code: string) => {
      const token= await fetchToken(code) as Tokens;
      console.log(`sgu-debug token: ${JSON.stringify(token)}`)
      // The value we return becomes the `fulfilled` action payload
      return token;
    }
  );
  
  

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.idToken = null;
            state.accessToken = null;
            state.userId = null;
            state.expire = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginWithCode.pending, (state) => {
                state.state = 'loading';
            })
            .addCase(loginWithCode.fulfilled, (state, action) => {
                console.log(`sgu-debug action.payload: ${JSON.stringify(action.payload)}`)
                state.state = 'idle';
                state.idToken = action.payload.id_token;
                state.accessToken = action.payload.access_token;
                state.expire = Date.now() + action.payload.expires_in * 1000 - 60000; // 1 minute to account for overhead
                // @ts-ignore
                state.userId = jwtDecode(action.payload.id_token!)!.sub;
            })
            .addCase(loginWithCode.rejected, (state) => {
                state.state = 'failed';
            });
    },
});

export const selectIdToken = (state: RootState) => state.auth.idToken;
export const selectAuthState = (state: RootState) => state.auth.state;
export const selectUserId = (state: RootState) => state.auth.userId;
export const selectExpire = (state: RootState) => state.auth.expire;

export const { logout } = authSlice.actions;

