import { Tokens } from './authSlice';
import { backend_url } from '../../backendAPI'; 

const redirect_uri = `${process.env.REACT_APP_FRONTEND_URL}/signin`;
const logout_uri = `${process.env.REACT_APP_FRONTEND_URL}`;
const client_id = '6i11lsv7o3cu0t0shohp271k0c';
export const authEndpoint = 
`https://littlecutie-dev.auth.us-west-1.amazoncognito.com/oauth2/authorize?client_id=${client_id}&response_type=code&scope=email+openid+profile&redirect_uri=${redirect_uri}`

// logout endpoint doesn''t actually invalidate the access token. It only clears the session in the browser. Only make sense when we deploy our UI to the hosted domain. 
export const logoutEndpoint =
`https://littlecutie-dev.auth.us-west-1.amazoncognito.com/logout?client_id=${client_id}&logout_uri=${logout_uri}`;


export const fetchToken = async (code: string) => {
   
    // URLSearchParams is a built-in browser API and not supported in React Native
    // Cf: https://stackoverflow.com/questions/35325370/how-do-i-post-a-x-www-form-urlencoded-request-using-fetch
    const bodyParams = new URLSearchParams();
    bodyParams.append('code', code);
    bodyParams.append('redirect_uri', redirect_uri); 

    const response = await fetch(`${backend_url}/token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-csrf-token': '',
        },
        body: bodyParams,
    });
    if (!response.ok) {
        const res = await response.json();
        console.log(`sgu-debug failed to fetch token: ${JSON.stringify(res)}`)
        throw new Error(`Failed to fetch token: ${res.error}`);
    }
    return response.json() as Promise<Tokens>;
};
