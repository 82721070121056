import { array, object, string } from 'yup';

// Generated by https://quicktype.io
export interface PlaylistUrlItemRequest {
    title: string;
    url:   string; // user input url of the video
};

export const SchemaPlaylistUrlItemRequest = object({
    title: string().trim().nullable().default(''),
    url: string().trim().required()
});

export interface ObjMeta {
    state: "ok" | "wip" | "unknown";
    signedUrl: string;  // playable url 
    duration?:  number; // "the duration of the playable in seconds"
    expire:    number | null; // "epoch time in seconds when the signed url will expire"
};

export interface PlaylistUrlItemResponse {
    title: string;
    url:   string; // user input url of the video
    url_or_objkey:   string; 
    objMeta? : ObjMeta;
};

export interface PlaylistResponse {
    username: string;
    playlist: string ; // name of the playlist
    urls: PlaylistUrlItemResponse[];
};

export interface PlaylistRequest {
    username: string;
    urls: PlaylistUrlItemRequest[];
    playlist: string; // name of the playlist
};

export const SchemaPlaylistRequest = object({
    username: string().required(),
    playlist: string().trim().required(),
    urls: array().of(SchemaPlaylistUrlItemRequest).required()
});
