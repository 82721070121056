import { LoaderFunction } from 'react-router';
import { store } from '../app/store';
import { fetchObjMeta } from '../backendAPI';
import { ObjMeta } from '../schemas';


export const getObjMetaLoader: LoaderFunction<ObjMeta|null> = async ({params}) => {
    const token = store.getState().auth.accessToken;
    if(token) {
        const objKey = params.objKey as string;
        const rv = await fetchObjMeta(objKey, token);
        return rv;

    } else { 
        return null 
    }
}