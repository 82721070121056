import React from 'react';
import { Outlet } from 'react-router';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useAppSelector } from './app/hooks';
import { selectUserId } from './features/auth/authSlice';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { SignIn, RequireAuth, SignOut } from './features/auth/auth';
import Home, { loader as homeLoader } from './features/Home';
import Playlist, { loader as plLoader, action as plAction } from './features/Playlist';
import ErrorBoundary from './features/ErrorBoundary'; 
import { getObjMetaLoader } from './resources/objects';
function App() {
  const userId = useAppSelector(selectUserId);
  return (
      <div className="App" style={{ height: '100%' }}>
        <h1>Welcome to MyPlaylist</h1>
        <Navbar expand="lg" className="bg-body-tertiary">
          <Navbar.Toggle aria-controls="playlist-navbar-nav" />
          <Navbar.Collapse id="playlist-navbar-nav">
            <Nav className="me-auto">
              {/* as={Link} to avoid reloading the page Cf: https://stackoverflow.com/questions/68268364/how-to-avoid-reloading-the-entire-app-when-using-nav-link-from-react-bootstrap */}
              { !userId ? 
                  <Nav.Link href="/signin" as={Link} to="/signin"> Sign In</Nav.Link> 
                  :
                  <Nav.Link href="/signout"  as={Link} to="/signout"  >Sign Out</Nav.Link> }
              <Nav.Link href="/i/playlists"  as={Link} to="/i/playlists">My Playlists</Nav.Link> 
              <Nav.Link href='/i'  as={Link} to='/i'  >Index</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Outlet />
      </div>
  );
}

const appRouter = createBrowserRouter([
  { path: '/', 
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      { path: 'signin', element: <SignIn /> },
      { path: 'signout', element: <SignOut />},
      {
        path: 'i', element: <RequireAuth />,
        children: [
          { index: true, element: <Navigate to="playlists" replace={true} /> },
          { path: 'playlists' , 
            loader: homeLoader,
            element: <Home />,
            children: [
              { path: ':playlistId' , 
                loader: plLoader,
                action: plAction,
                element: <Playlist /> }, 
              { index: true, element: <div>choose a playlist</div>},
              { path: '*', element: <div>404</div>}
            ]
          },
        ]
      }, 
      {
        /* resource routes for fetcher */
        path: 'resources/objects/:objKey',
        loader: getObjMetaLoader
      }
    ]
  },
]);

export default appRouter;
