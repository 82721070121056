import React, { Fragment, useEffect, useState } from 'react';
import { redirect, useLoaderData, useParams, useSubmit, json, useNavigation } from 'react-router-dom';
import { Container, Row , Form, Col, Button } from 'react-bootstrap';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { ErrorMessage } from "@hookform/error-message"
import { store } from '../app/store';
import { fetchPlaylist, createPlaylist, updatePlaylist, } from '../backendAPI';
import { PlaylistResponse, PlaylistRequest, PlaylistUrlItemRequest, SchemaPlaylistRequest } from '../schemas';
import MediaPlayer from './MediaPlayer';


// @ts-ignore
export async function loader({params}) {
    const userId = store.getState().auth.userId;
    // @ts-ignore
    const pl = params.playlistId;

    let rv: PlaylistResponse;
    if (userId){
        // '_' is for new playlist in which case we don't need to fetch anything
        console.debug('loader playlistId: ', pl);
        if (pl === '_') {
            // new playlist
            rv = {
              username: userId,
              playlist: '',
              urls: []  
            }
            return rv as PlaylistResponse;
        } else {
            const token = store.getState().auth.accessToken as string;
            rv = await fetchPlaylist(userId, pl, token);
            console.debug('loader playlist: ', rv);
            return rv; 
        }
    } else {
        return null;
    }
}

// @ts-ignore
export async function action({params, request}) {
    const isNew = params.playlistId === '_';
    const userId = store.getState().auth.userId as string;
    const token = store.getState().auth.accessToken as string;
    const data0 = await request.json();
    const data = SchemaPlaylistRequest.cast(data0, {stripUnknown: true}) as PlaylistRequest;
    let res;
    console.debug('calling action with data:', data);
    
    try {
        if (isNew) {
            res = await createPlaylist(userId, data, token);
        } else {
            res = await updatePlaylist(userId, params.playlistId, data, token);
        }
    } catch (error) {
        // console.error('action error:', error);
        throw json({
            message: "Connection error. Please check network and try again."
        }, {status: 400})
    }
    
    if (!res.ok) {
        throw res; 
    }
    // redirect to all playlist views to give time for dynamoDB to update
    return redirect('..');

}

const Playlist: React.FC = () => {
    const params = useParams() as {playlistId: string};
    console.debug('Playlist render with params:', params);
    return (
        // adding key to force re-mount when the playlistId changes
        <PlaylistInner key={params.playlistId} />
    );
}

const PlaylistInner: React.FC = () => {
    const playlist = useLoaderData() as PlaylistResponse;
    const formDefault = SchemaPlaylistRequest.cast(playlist, {stripUnknown: true}) as PlaylistRequest;
    const navigation = useNavigation();

    // the submit from react-router can handle error redirect and revalidate from the action 
    const _submit = useSubmit();

    const params = useParams() as {playlistId: string};
    const pl = params.playlistId;
    const isNew = pl === '_';
    const [isEdit, setEdit] = useState(isNew);

    const { register, control, handleSubmit, formState: { errors, dirtyFields } } = useForm<PlaylistRequest>(
        { defaultValues: formDefault }
    );
    const { fields, append, insert, remove, swap } = useFieldArray({ control, name: 'urls' });
    const defaultArrayItem : PlaylistUrlItemRequest = {
        url: '',
        title: ''
    };
    const onSubmit: SubmitHandler<PlaylistRequest> = async (data) => {
        console.log('submitting:', data);

        // @ts-ignore ts(2345)
        _submit(data, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            encType: "application/json"
        });

    };
    useEffect(() => {
        console.log('Playlist mounted');
    
        return () => {
            console.log('Playlist unmounted');
        }
    }, []);
    return (
        navigation.state === 'loading' ? (<div> Loading... </div>) :
        (<Container fluid="md">
            { !isEdit && <button className="d-block my-2" onClick={()=>setEdit(true)}>  Edit  </button>}
            <Form onSubmit={handleSubmit(onSubmit)}>
                {isEdit && (
                    <>
                        <Button onClick={()=>setEdit(false)} disabled={navigation.state === 'submitting'} > Abort </Button> {''}
                        <Button type="submit" disabled={navigation.state === 'submitting'} > 
                            { navigation.state === 'submitting' ? 'Submitting': 'Submit' } 
                        </Button> 
                    </>)}
                
                {/* for hidden field: Cf: https://react-hook-form.com/docs/useform#defaultValues */}
                <input type="hidden" value={playlist.username}/>
                <Form.Group as={Row} className="mb-3 align-items-center" controlId={`playlist-${pl}-name`}>
                    <Form.Label column sm="2" className="position-relative">
                        Name 
                        {isEdit && <span style={{color: 'red', position: 'absolute', top: 0}}>*</span>}
                    </Form.Label>
                    <Col sm="10">   
                        <Form.Control plaintext={!isEdit} readOnly={!isEdit} {...register("playlist", {'required': {
                            value: true,
                            message: 'required'
                        }})} 
                        isInvalid = { errors.playlist !== undefined } 
                        className="align-middle"
                        />
                        { isEdit && <ErrorMessage errors={errors} name="playlist" 
                                     render={({ message }) => <Form.Control.Feedback type="invalid"> {message} </Form.Control.Feedback> }
                                     /> } 
                    </Col>
                </Form.Group>
                <hr/>
                 { !isEdit ? 
                    playlist.urls.map(({url, url_or_objkey, title, objMeta=null}, idx) => (
                        <Fragment key={idx}>
                            <Form.Group as={Row} className="mb-3 align-items-center" controlId={`playlist-${pl}-${idx}-url`} key={`pl-${idx}-url`}>
                                <Form.Label column sm="2">
                                    URL 
                                </Form.Label>
                                <Col sm="10">   
                                    <Form.Control plaintext readOnly={!isEdit} defaultValue={url} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId={`playlist-${pl}-${idx}-title` } key={`pl-${idx}-title`}>
                                <Form.Label column sm="2">
                                    Title 
                                </Form.Label>
                                <Col sm="10">   
                                    <Form.Control plaintext readOnly={!isEdit} defaultValue={title} />
                                </Col>
                            </Form.Group>
                            {!isEdit && <MediaPlayer url_or_objkey={url_or_objkey} objMeta={objMeta} key={`pl-${idx}-media`} />}
                            <hr />
                        </Fragment >
                    ))
                : // isEdit is true 
                    <>
                    {fields.map((field, idx) => (
                        <Fragment key={field.id}>
                        <div>
                                <Button onClick={()=>insert(idx, defaultArrayItem)} className='me-2'> + </Button> 
                                <Button onClick={()=>remove(idx)} className='me-2'> - </Button>
                                <Button onClick={()=>swap(idx, idx+1)} disabled={idx >= fields.length - 1 } className='me-2'> ▼ </Button>
                                <Button onClick={()=>swap(idx, idx-1)} disabled={idx === 0} className='me-2'> ▲ </Button>
                        </div>
                        <Row className='align-items-center'>                           
                            <Form.Group as={Row} className="mb-3 align-items-center" controlId={`playlist-${pl}-${field.id}-url`} >        
                                <Form.Label column sm="2" className="position-relative">
                                    URL 
                                    <span style={{color: 'red', position: 'absolute', top: 0}}>*</span>
                                </Form.Label>
                                <Col sm="7">   
                                    <Form.Control   defaultValue={field.url} {...register(`urls.${idx}.url`, {required: {
                                        value: true,
                                        message: 'required'
                                    }})}
                                    isInvalid = {errors.urls?.[idx] != null  && dirtyFields.urls?.[idx] != null} 
                                    />
                                    <ErrorMessage errors={errors} name={`urls.${idx}.url`} 
                                    render={({ message }) => <Form.Control.Feedback type="invalid"> {message} </Form.Control.Feedback> }
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 align-items-center" controlId={`playlist-${pl}-${field.id}-title` } >
                                <Form.Label column sm="2">
                                    Title 
                                </Form.Label>
                                <Col sm="10">   
                                    <Form.Control  defaultValue={field.title} {...register(`urls.${idx}.title`)}/>
                                </Col>
                            </Form.Group>
                        </Row >
                        <hr />
                        </Fragment>
                    ))}
                    <Button onClick={()=>append(defaultArrayItem)}> + </Button> 
                    </>
                }
            </Form>
        </Container>)
        );
};

export default Playlist;
