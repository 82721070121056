import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import type { NavLinkProps } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Outlet, useLoaderData } from 'react-router';
import { store } from '../app/store';
import { fetchPlaylists } from '../backendAPI';

type Props = {  
};

// Cf: https://github.com/remix-run/react-router/blob/74f86766d0cd3ba9d4f14c4d82957bfd4f7c73e3/packages/react-router-dom/index.tsx#L1010
type NavLinkRenderProps = {
    isActive: boolean;
    isPending: boolean;
    isTransitioning: boolean;
  };

export async function loader() {
    console.log('Home loader running');
    const userId = store.getState().auth.userId;
     if (userId) {
        const token = store.getState().auth.accessToken as string;
        const playlists = await fetchPlaylists(userId, token) as string[];
        console.log('loader playlists: ', playlists);
        return playlists;
     } else { 
        return null;
     }
}

const Home: React.FC<Props> = () => {
    const playlists = useLoaderData() as string[];
    const navigate = useNavigate();

    // Cf: Can more than two flags set at the same time? Seems so from the doc: https://reactrouter.com/en/main/components/nav-link#classname
    const navLinkClass = React.useCallback(({ isActive, isPending, isTransitioning }: NavLinkRenderProps) => {
        // console.debug('navLinkClass isActive:', isActive, 'isPending:', isPending, 'isTransitioning:', isTransitioning);
        return isActive ? "active": isPending ? "pending" : isTransitioning ? "transitioning" : ""},[]);
    React.useEffect(() => {
        console.debug('Home mounted');
        return () => {
            console.debug('Home unmounted');
        }
    },[]);
    console.debug('Home render');
    return (
        <Container className="ms-0 h-100" fluid="xs" >
            <Row className='h-md-100'>
                <Col id="sidebar" md={3} className='border-end' style={{ backgroundColor: 'black' }}>
                    { playlists.length > 0 ? 
                        (<nav>
                            <ul>
                                {playlists.map((plName) => (
                                    <li key={plName}>
                                        <NavLink to={plName}
                                            className={navLinkClass}
                                        >
                                            {plName}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </nav>): null
                    }
                    <button className='d-block my-2' onClick={()=>navigate('_')}> new </button>
                </Col>
                <Col md={9}  style={{ backgroundColor: 'white' }}>
                    <Outlet />
                </Col>
            </Row>
        </Container>
    );
}

export default Home;