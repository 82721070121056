import React, { MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { ObjMeta } from '../schemas';
import { useFetcher } from 'react-router-dom';
import Button from 'react-bootstrap/Button';


export interface MediaPlayerProps {
    objMeta: ObjMeta|null;
    url_or_objkey: string|null;
}
const MediaPlayer: React.FC<MediaPlayerProps> = ({objMeta, url_or_objkey}: MediaPlayerProps) => {
    const fetcher = useFetcher<ObjMeta|null>(); // null if token invalid

    const isObjMetaNull = useMemo(() => objMeta === null, [objMeta]);


    const fetchUtil = useCallback(() => {
        if (url_or_objkey) {
            const k = encodeURIComponent(url_or_objkey);
            const u = `/resources/objects/${k}`;
            fetcher.load(u);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url_or_objkey]); 

    const fetchCB: MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
        e.preventDefault();
        fetchUtil();
    }, [fetchUtil]);

    const [valid, setValid] = useState(true);
    

    React.useEffect(() => {
        if (isObjMetaNull && url_or_objkey) {
            fetchUtil();
        }
    // useEffect should not depend on fetcher unless we want the fetch data change to trigger a re-run 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isObjMetaNull, url_or_objkey]);
    

    return (
        <div>
            { objMeta ? (
                // objMeta is not null only when the url is a permanent
                valid ? (<audio  onError={()=>setValid(false)} controls >
                        <source src={objMeta.signedUrl} type={"audio/mpeg"}/>
                        Your browser does not support the audio element.
                        </audio>) : (<div> Media format is not supported. Please make sure it's publicly reachable and playable. </div>)
            ): (
                (fetcher.state==='idle') ? 
                    fetcher.data ? 
                        fetcher.data.state === 'ok' ?
                            valid ? (<audio controls onError={()=>setValid(false)}>
                                <source src={fetcher.data.signedUrl} type={"audio/mpeg"}/>
                                    Your browser does not support the audio element. </audio>) : 
                            (<div> Media has expired. Please <Button variant="link" onClick={fetchCB} className="mediaplayer-btn-link" >refresh</Button>. </div>)
                        : fetcher.data.state === 'wip' ? 
                                (<div> Media is being processed. Please <Button variant="link" onClick={fetchCB} className="mediaplayer-btn-link"> refresh </Button> later. </div>) :
                            // state === 'unknown'
                            (<div> Sorry, this media is not supported.  </div>)
                    : <div> Session has expired. Please login again. </div>
                : <div> Loading... </div>
            )}
        </div>
    );
};

export default MediaPlayer;
