import { ObjMeta, PlaylistRequest, PlaylistResponse } from './schemas';
export const backend_url = process.env.REACT_APP_BACKEND_URL;


export const fetchPlaylists = async (userId: string, token: string, details: boolean = false) => {
    const response = await fetch(`${backend_url}/private/users/${userId}/playlists?details=${details}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch playlists: ${response.status}`);
    }
    if (details) {
        return response.json() as Promise<PlaylistResponse[]>;
    } else {
        return response.json() as Promise<string[]>;
    }
}

export const fetchPlaylist = async (userId: string, playlistName: string, token: string) => {
    const response = await fetch(`${backend_url}/private/users/${userId}/playlists/${playlistName}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch playlist: ${response.status}`);
    }
    return response.json() as Promise<PlaylistResponse>;
}

/**
 * Fetches the metadata of an object.
 * @param objKey - The key of the object; which has been decode by react-router
 * @param token - The authentication token
 * @returns A promise that resolves to the object metadata
 * @throws An error if the request fails
 */
export const fetchObjMeta = async (objKey:string, token: string) => {
    
    const response = await fetch(`${backend_url}/private/objects/${encodeURIComponent(objKey)}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch objMeta: ${response.status}`);
    }
    return response.json() as Promise<ObjMeta>;
}

export const createPlaylist =  (userId: string, playlist: PlaylistRequest, token: string) => {
    return fetch(`${backend_url}/private/users/${userId}/playlists`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(playlist)
    });
    // if (!response.ok) {
    //     const res = await response.json();
    //     throw new Error(`${res.message}`);
    // }
    // return response;
}

export const updatePlaylist = (userId: string, playlistName: string, playlist: PlaylistRequest, token: string) => {
    return fetch(`${backend_url}/private/users/${userId}/playlists/${playlistName}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(playlist)
    });
}